<template>
  <div>
    <v-app-bar :color="getBleu" short app>
      <v-app-bar-nav-icon
        @click="toggleMenu"
        :style="`color: ${getBlanc}`"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title :style="`color: ${getBlanc}`">{{
        currentRouteName | uppercase
      }}</v-toolbar-title>

      <!--Mes onglets de gestion des utilisateurs/agences/societes dans ma nav Admin-->
      <template v-slot:extension v-if="currentRouteName === 'Administration'">
        <v-tabs
          :value="getTab"
          @change="setTab"
          align-with-title
          :color="getBlanc"
        >
          <v-tabs-slider :color="getBlanc"></v-tabs-slider>
          <v-tab v-for="tab in getTabs" :key="tab.key" class="white--text">{{
            tab.title
          }}</v-tab>
        </v-tabs>
      </template>

      <!--Mes onglets de gestion des bons de commande/couleurs/essences/finitions/formes dans ma nav bc-->
    </v-app-bar>

    <v-navigation-drawer
      app
      dark
      :style="`background: linear-gradient(${getBleu}, ${getTurquoisePoupin});`"
      v-model="showMenu"
      mobile-breakpoint="1000"
    >
      <template v-slot:prepend>
        <v-list-item style="background-color: white" class="pa-2">
          <v-img src="@/assets/poupin_logo.jpg"></v-img>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-avatar color="grey" rounded>
            <!--<img src="https://randomuser.me/api/portraits/men/32.jpg">-->
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              >{{ userInSession.prenom | capitalize }}
              {{ userInSession.nom | capitalize }}</v-list-item-title
            >
            <v-list-item-subtitle>{{
              userInSession.role | capitalize
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          to="/"
          v-if="isAdmin || isPoseur || isConducteur || isPoseurReno || isResponsableReno"
        >
          <v-list-item-icon>
            <v-icon>mdi-home-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Accueil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/times" v-if="isAdmin || isPoseur">
          <v-list-item-icon>
            <v-icon>mdi-clock-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Mes horaires</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/order" v-if="isAdmin || isPoseur || isPoseurReno || isResponsableReno">
          <v-list-item-icon>
            <v-icon>mdi-cart-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Commande de matériel</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/direction" v-if="isAdmin || isDirection || isBE">
          <v-list-item-icon>
            <v-icon>mdi-hard-hat</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Chantiers et interventions</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/ordonnancement" v-if="isAdmin || isDirection || isBE">
          <v-list-item-icon>
            <v-icon>mdi-chart-gantt</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Ordonnancement</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          to="/manageTimes"
          v-if="isAdmin || isGestionnaire || isDirection"
        >
          <v-list-item-icon>
            <v-icon>mdi-clock-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Gestion des horaires</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          to="/infosUtiles"
          v-if="
            isAdmin ||
              isGestionnaire ||
              isConducteur ||
              isPoseur ||
              isPoseurReno ||
              isResponsableReno
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-information-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Informations utiles</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item to="/bonsCommande" v-if="isAdmin">
          <v-list-item-icon>
            <v-icon>mdi-file-document-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Bons de commande</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-list-item
          to="/livraison"
          v-if="isAdmin || isGestionnaire || isLivreur"
        >
          <v-list-item-icon>
            <v-icon>mdi-truck-fast-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Livraison</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          to="/PageLeaflet"
          v-if="isAdmin || isGestionnaire || isConducteur || isLivreur"
        >
          <v-list-item-icon>          
            <v-icon>mdi-map-marker-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>MAPS / Mes interventions</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        
 

        <v-list-item to="/admin" v-if="isAdmin">
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Administration</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list-item>
        <v-btn block text @click="logout">
          <span>Se déconnecter </span>
          <v-spacer></v-spacer>
          <v-icon>mdi-logout-variant</v-icon>
        </v-btn>
      </v-list-item>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "x-nav",
  data() {
    return {
      showMenu: true,
    };
  },
  props: {},
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/login");
      });
    },
    setTab(e) {
      this.$store.dispatch("admin/setTab", e);
    },
    setTabBc(e) {
      this.$store.dispatch("bcTabs/setTabBc", e);
    },
  },
  computed: {
    ...mapGetters(["getTurquoisePoupin", "getBleu", "getBlanc", "getGris"]),

    ...mapGetters("admin", {
      getTabs: "getTabs",
      getTab: "getTab",
    }),

    ...mapGetters("bcTabs", {
      getTabsBc: "getTabsBc",
      getTabBc: "getTabBc",
    }),

    ...mapGetters("auth", {
      userInSession: "userInSession",
      isConnected: "isConnected",
      isAdmin: "isAdmin",
      isPoseur: "isPoseur",
      isGestionnaire: "isGestionnaire",
      isDirection: "isDirection",
      isBE: "isBE",
      isConducteur: "isConducteur",
      isLivreur: "isLivreur",
      isResponsableAlu: "isResponsableAlu",
      isResponsablePVC: "isResponsablePVC",
      isResponsableBOIS: "isResponsableBOIS",
      isPoseurReno: "isPoseurReno",
      isResponsableReno: "isResponsableReno",
    }),
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style scoped></style>
